body {
  font-family: Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  max-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(175, 89, 255);
  padding: 0;
}

.App-header .brand {
  padding: 20px;
  border: 1px solid rgb(175, 89, 255);
  min-width: 400px;

  /* gradient background */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(107, 17, 192, 0.5) 100%);
}

.App-link {
  color: #61dafb;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0 auto 20px auto;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(97, 16, 162);
  max-width: 854px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navigation {
  padding: 20px;
}

.navbutton {
  padding: 10px;
  color: rgb(1, 1, 1);
  font-weight: bold;
  margin-right: 10px;
  font-size: 24px;
  transition: color 0.5s;
}

.navbutton:last-child {
  margin-right: 0;
}

.section, .section .section-content {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #000;
  color: #f1f1f1;
}

.section .section-content {
  padding: 80px 0;
}

.footer {
  position: fixed;
  bottom: 0;
  font-size: 16px;
  padding: 40px 0;
  width: 100%;
  color: #666;
  border-top: 1px solid #333;
  background-color: #101010;
}

.about, .contact {
  box-sizing: border-box;
  padding: 20px;
  max-width: 854px;
  margin: 40px auto 0 auto;
}

.contact
{
  padding-top: 100px;
  background: none;
  text-align: left;
}

.top-navigation {
  background-color: #0f0f0f;
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  background-color: rgb(97, 16, 162);
  border-bottom: 2px solid rgb(171, 103, 255);
}

.navigation-container {
  display: grid;
  text-align: left;
  grid-template-columns: 50% 50%;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
}

.navigation .navbutton {
  color: rgb(234, 217, 255);
}

.navigation .navbutton:hover {
  cursor: pointer;
  color: rgba(146, 65, 243, 1);
}

.top-navigation {
  min-height: 70px;
}

.top-navigation .navbar {
  text-align: left;
  margin-left: 140px;
}

.top-navigation .navbar .navbutton {
  display: inline-block;
  font-size: 20px;
  color: rgba(146, 65, 243, 1);
}

.top-navigation .navbar .navbutton.selected {
  color: rgb(234, 217, 255);
}
.top-navigation .navbar .navbutton:hover {
  color: rgb(234, 217, 255);
  cursor: pointer;
}

.top-navigation .brand {
  font-size: 24px;
  font-weight: bold;
  padding-top: 5px;
}

.top-navigation .brandname {
  transition: color 0.5s;
  max-width: 108px;
  max-height: 108px;
  width: 108px;
  height: 108px;
  margin-left: 20px;
  position: absolute;
}

.top-navigation .brandname img {
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
  position: absolute;
  border-top-left-radius: 50% 50%; border-top-right-radius: 50% 50%; border-bottom-right-radius: 50% 50%; border-bottom-left-radius: 50% 50%;
  border: 4px solid rgb(171, 103, 255);
  transition: border 0.5s;
}

.top-navigation .brandname:hover img {
  cursor: pointer;
  border: 4px solid rgb(234, 217, 255);
}

.home-logo {
  width: 400px;
  height: 400px;
}

a {
  color: rgb(234, 217, 255);
  text-decoration: none;
}

a:hover {
  color: rgba(146, 65, 243, 1);
}

.top-navigation a {
  color: rgba(146, 65, 243, 1);
  transition: color 0.5s;
  margin-right: 10px;
}

.top-navigation a:hover {
  color: rgb(234, 217, 255);
}

.socialLinks {
  height: 20px;
  margin-top: 3px;
  margin-left: 20px;
  text-align: right;
  width: 100%;
}

.socialLinks ul {
  list-style: none;
  margin-right: 20px;
}

.socialLinks ul li {
  font-size: 18px;
  display: inline-block;
  margin-right: 20px;
}

.socialLinks ul li a {
  display: inline-block;
  border: 1px solid rgba(146, 65, 243, 1);
  border-radius: 5px;
  padding: 10px;
}

.section-content .content {
  text-align: left;
}

.section-content .content p {
  line-height: 24px;
  margin-bottom: 20px;
}

.section-content .content p:last-child {
  margin-bottom: 0;
}

.section-content .content p:last-child {
  margin-bottom: 0;
}

.socialLinks-collapsebutton {
  display: none;
  width: 20px;
  height: 20px;
  width: 100%;
  text-align: right;
}

.socialLinks-collapsebutton button {
  margin-right: 40px;
}

.socialLinks-collapsebutton button:hover {
  cursor: pointer;
}

.hamburgerButton {
  font-size: 18px;
  color: rgba(146, 65, 243, 1);;
  border: 1px solid rgba(146, 65, 243, 1);;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px 15px; 
}

/* collapse .socialLinks into dropdown menu when screen size is small */
@media screen and (max-width: 868px) {
  .navbar .navbutton {
    font-size: 10px;
  }

  .socialLinks ul {
    display: none;
  }

  .socialLinks-collapsebutton {
    display: block;
  }

  .top-navigation .navbar .navbutton {
    display: none;
  }

  .hiddenNav {
    position: relative;
    left: -40px;
    display: block;
    background: #000;
    border: 2px solid rgb(97, 16, 162);
  }
  .hiddenNav ul {
    display: block;
  }

  .hiddenNav ul li {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: left;
    margin-left:
  }

  .hiddenNav ul li a {
    width: 20px;
  }

  .hiddenNav ul.social li {
    display: inline-block;
  }

  .hiddenNav ul.nav {
    margin-bottom: 20px;
  }
  .hiddenNav ul.nav .navbutton {
    color: rgb(175, 89, 255);
    font-size: 20px;
    text-align: center;
    transition: all 0.5s;
    background-color: #202020;
    width: 100%;
    display: block;
  }

  .hiddenNav ul.nav .navbutton:hover {
    color: rgb(234, 217, 255);
    cursor: pointer;
    background-color: #000;
  }

  .content {
    margin-right: 20px;
    margin-left: 20px;
  }

  .meta-data {
    display: block;
  }

  .projects .project {
    border: none;
  }
}

h3 {
  font-size: 18px;
  font-weight: bold;
}