.section-content {
  display: flex;
  justify-content: center; /* Center the child elements horizontally */
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Styles for the project container */
.project {
  padding: 50px 0 !important;
  max-width: 980px;
  width: 100%; /* Ensure the container takes full width up to max-width */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Styles for the project title */
.project h3 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: left;
}

/* Styles for the project description */
.project .description {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
}

/* Styles for the meta-data section */
.project .meta-data {
  margin-bottom: 20px;
  text-align: left;
}

.project .meta-data .marketplaces {
  margin-bottom: 10px;
}

.project .meta-data .marketplaces small {
  font-size: 14px;
  color: #999;
}

.project .meta-data .marketplaces a {
  color: #266400;
  font-size: 20px;
}

.project .meta-data .details {
  width: 100%;
  border-collapse: collapse;
}

.details td {
  padding: 5px 10px;
  border-top: 1px solid #333;
}

table.details {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.project .meta-data .details td strong {
  color: #333;
}

.project .images {
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap; /* Ensure images are in a single row */
  gap: 10px; /* Add spacing between images */
  margin-bottom: 20px;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}

.project .images .image-container {
  position: relative;
  cursor: pointer;
  flex: 0 0 auto; /* Prevent images from shrinking */
}

.project .images .image-container img {
  width: 256px;
  height: 144px;
  object-fit: cover;
  border-radius: 4px;
}

.project .images .image-container .image-text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.project .images .image-container .image-text {
  font-size: 14px;
}

/* Styles for the cover image */
.project .coverimage {
  border-radius: 8px;
  margin-top: 20px;
  margin-right: 20px;
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.project-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.project-header img {
  max-width: 100%;
  height: auto;
}

.project-header div {
  flex: 1;
  min-width: 300px; /* Ensure minimum width for text container */
}

/* Responsive styles */
@media (max-width: 768px) {
  .project {
    padding: 10px;
    padding: 60px;
  }

  .project h3 {
    font-size: 20px;
  }

  .project .description {
    font-size: 14px;
  }

  .project .meta-data .marketplaces a {
    font-size: 18px;
  }

  .project .images  {
    max-width: 435px;
  }

  .project .images .image-container img {
    width: 100%;
    height: auto;
  }

  table td {
    font-size: 12px;
  }
}