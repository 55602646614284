/* Ensure no conflicting styles */
.projects {
  padding: 130px 0 !important;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
  gap: 16px;
}

.projects .item {
  padding: 16px;
}

.projects .item img {
  border-radius: 8px;
  margin-bottom: 5px;
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

.caption {
  font-size: 1.25rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .projects {
    padding: 130px 0 !important;
    display: flex;
    flex-direction: column; /* Stack projects vertically on mobile */
  }

  .projects .item {
    padding: 8px; /* Adjust padding for mobile if needed */
  }

  .projects .item img {
    width: auto; /* Ensure images take full width on mobile */
    height: auto; /* Maintain aspect ratio */
  }
}